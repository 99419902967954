
export default {
  props: {
    srcDate: {
      type: String,
      default: ''
    },
    dateFormat: {
      type: String,
      default: 'en-US'
    },
    displayType: {
      type: String,
      default: 'YMD'
    },
    nullDateText: {
      type: String,
      default: ''
    },
    newLine: {
      type: Boolean,
      default: false
    },
    suffix: {
      type: String,
      default: ''
    }
  }
}
