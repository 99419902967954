import { render, staticRenderFns } from "./VoterReturnBallot.vue?vue&type=template&id=e99b85d8"
import script from "./VoterReturnBallot.vue?vue&type=script&lang=js"
export * from "./VoterReturnBallot.vue?vue&type=script&lang=js"
import style0 from "./VoterReturnBallot.vue?vue&type=style&index=0&id=e99b85d8&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VfaScrollUp: require('/opt/build/repo/components/VfaScrollUp.vue').default,At: require('/opt/build/repo/components/At.js').default,ADateRenderer: require('/opt/build/repo/components/ADateRenderer.vue').default,VoterResourceButtons: require('/opt/build/repo/components/VoterResourceButtons.vue').default,ChatBot: require('/opt/build/repo/components/ChatBot.vue').default})
